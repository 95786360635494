import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';

import type { EconomicSettingModuleProps } from '@src/pages/settings/types';

export const EconomicUpdateCustomer: React.FC<EconomicSettingModuleProps> = ({ economicUserDefaultData, setEconomicUserDefaultData }) => {
  const handleUpdateCustomerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        disable_update_customer: event.target.checked,
      });
    }
  };

  return (
    <Form.Group as={Row} className="mb-3" controlId="formHorizontalDisableCustomerUpdates">
      <Form.Label column sm={6}>
        Prevent customer updates to e-conomic
      </Form.Label>
      <Col sm={6}>
        <Form.Check
          type="switch"
          id="email-upload-switch"
          label={economicUserDefaultData.disable_update_customer ? 'Enabled' : 'Disabled'}
          checked={economicUserDefaultData.disable_update_customer ? economicUserDefaultData.disable_update_customer : false}
          onChange={handleUpdateCustomerChange}
        />
      </Col>
    </Form.Group>
  );
};
