import { EconomicSettingModuleProps } from '@src/pages/settings/types';
import React from 'react';
import { Col, Form, FloatingLabel, Row } from 'react-bootstrap';

export const EconomicAddress: React.FC<EconomicSettingModuleProps> = ({ economicUserDefaultData, setEconomicUserDefaultData }) => {
  const handleSelectBillingAddress = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        selected_billing_address: event.target.value,
      });
    }
  };

  const handleEnableDeliveryLocationsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        delivery_locations_enabled: event.target.checked,
      });
    }
  };

  const handleSelectDeliveryAddress = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (setEconomicUserDefaultData && economicUserDefaultData.delivery_locations_enabled) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        selected_delivery_address: event.target.value,
      });
    }
  };

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalSelectedBillingAddress">
        <Form.Label column sm={6}>
          Select which customer address to use as billing address in e-conomic
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingSelectBillingAddress" label="Select address to use for billing address">
            <Form.Select
              value={economicUserDefaultData.selected_billing_address ? economicUserDefaultData.selected_billing_address : 'visiting'}
              onChange={handleSelectBillingAddress}
            >
              <option hidden value="" data-key={'selected_billing_address'}>
                Open this select menu
              </option>
              <option value="visiting">Visiting address</option>
              <option value="mailing">Mailing address</option>
              <option value="billing">Billing address</option>
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="formHorizontalEnableDeliveryLocations">
        <Form.Label column sm={6}>
          Enable using delivery locations in e-conomic
        </Form.Label>
        <Col sm={6}>
          <Form.Check
            type="switch"
            id="email-upload-switch"
            label={economicUserDefaultData.delivery_locations_enabled ? 'Enabled' : 'Disabled'}
            checked={economicUserDefaultData.delivery_locations_enabled ? economicUserDefaultData.delivery_locations_enabled : false}
            onChange={handleEnableDeliveryLocationsChange}
          />
        </Col>
      </Form.Group>

      {economicUserDefaultData.delivery_locations_enabled && (
        <Form.Group as={Row} className="mb-3" controlId="formHorizontalSelectedDeliveryAddress">
          <Form.Label column sm={6}>
            Select which customer address to use as delivery location in e-conomic
          </Form.Label>
          <Col sm={6}>
            <FloatingLabel controlId="floatingSelectDeliveryAddress" label="Select address to use for delivery address">
              <Form.Select
                value={economicUserDefaultData.selected_delivery_address ? economicUserDefaultData.selected_delivery_address : 'visiting'}
                onChange={handleSelectDeliveryAddress}
              >
                <option hidden value="" data-key={'selected_delivery_address'}>
                  Open this select menu
                </option>
                <option value="visiting">Visiting address</option>
                <option value="mailing">Mailing address</option>
                <option value="billing">Billing address</option>
              </Form.Select>
            </FloatingLabel>
          </Col>
        </Form.Group>
      )}
    </>
  );
};
