import React from 'react';
import { Row, Col, Form, FloatingLabel } from 'react-bootstrap';

import type { EconomicSettingModuleProps } from '@src/pages/settings/types';

export const EconomicDocumentType: React.FC<EconomicSettingModuleProps> = ({ economicUserDefaultData, setEconomicUserDefaultData }) => {
  const handleInvoiceEventTypeChange = (event: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
    const option = event.target.options[event.target.selectedIndex];

    if (setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        document_type: `${option.value}`,
      });
    }
  };

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalEconomicDocumentType">
        <Form.Label column sm={6}>
          Specify which document type to create in e-conomic
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingSelectEconomicDocumentType" label="Select document type">
            <Form.Select
              aria-label="Floating label select"
              value={economicUserDefaultData.document_type ? economicUserDefaultData.document_type : 'invoice'}
              onChange={handleInvoiceEventTypeChange}
            >
              <option key={`economicDocumentType-1`} value="invoices">
                Invoice
              </option>

              <option key={`economicDocumentType-2`} value="quotes">
                Quote
              </option>

              <option key={`economicDocumentType-2`} value="orders">
                Order
              </option>
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Form.Group>
    </>
  );
};
